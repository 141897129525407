@import "~app/mixins";

$border-color: #dfe1e4;

.header {
  display: flex;
  justify-content: space-between;
  margin-top: -5px;
  padding-bottom: 15px;
  height: 50px;

  .buttons {
    margin-right: 25px;
    margin-top: 3px;
    display: flex;
    padding-top: 14px;
    div,
    a,
    button {
      font-family: $font-inter;
    }

    button {
      margin: 0 5px;
      font-weight: bold;
      font-size: 14px;
      width: auto;
      border-radius: 50px;
      background-color: #fff;
    }

    .iconExport {
      width: 26px;
      height: 24px;
    }
  }
}

.dropDownToggle {
  button {
    min-width: 200px;
  }
  button > div:nth-of-type(1) {
    justify-content: flex-start !important;
  }

  .toolsButton {
    display: flex;
    align-items: center;
    .buttonLabel {
      color: $color-white;
      font-size: 8px;
      font-weight: 700;
      background-color: $color-orange;
      border-radius: 999px;
      padding: 2px 8px;
      margin-left: 5px;
    }

    .iconCaretDown {
      fill: $color-teal;
      position: absolute;
      right: 15px;
      top: 8px;
    }
  }

  .dropdownList {
    background-color: $color-white;
    border: solid 1px $color-teal;
    border-radius: 15px;
    margin-top: 10px;
    position: relative;
    z-index: 999;
    margin: 5px;
  }

  .dropdown {
    background-color: $color-white;
    border: solid 1px $color-teal;
    border-radius: 15px;
    margin-top: 10px;
    position: relative;
    z-index: 999;
    margin: 5px;
    cursor: default;
    display: none;

    .dropdownItem {
      font-size: 14px;
      font-weight: 600;
      color: $color-grey;
      padding: 5px 15px;
      display: flex;
      align-items: center;
      text-decoration: none !important;

      &:hover {
        background-color: $color-grey-lighter;
      }
    }

    .topBorderItem {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }
    .botBorderItem {
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }

    &.open {
      display: block;
    }
  }
}